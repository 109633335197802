import axios from "axios";

import config from "../config";
import getHistory from "../components/utility/history";
import IncludeSentry from "../sentry";

export const API_URL = config.API_CONNECT_ROOT;

export function appendToken() {
  const token = localStorage.getItem("token");
  return token ? `JWT ${token}` : null;
}

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common["Authorization"] = appendToken();
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    /* Reset the error message at the top of the login page because the user is now
       interacting with the page and we don't want to cause confusion with old messaging.*/
    getHistory().push({
      state: {
        detail: "",
      },
    });

    /* The below if statement usually means there may be Network issue so ask the user to check
       their internet connection. */
    if (typeof error.response === "undefined") {
      localStorage.clear();
      getHistory().push({
        pathname: "/login/",
        state: {
          detail:
            "There appears to be a network issue. Please make sure you are connected to the internet.",
        },
      });
      return Promise.reject(error);
    }

    // If not a network issue then....
    const value = error.response;
    if (!value) {
      if (config.ENABLE_SENTRY) {
        IncludeSentry("Server down");
      }
      localStorage.clear();
      getHistory().push({
        pathname: "/login/",
        state: {
          detail: "Our server is temporarily down. Please sign in again later.",
        },
      });
      return Promise.reject(error);
    }

    if (config.ENABLE_SENTRY) {
      if (value) {
        const data = value.data;
        if (data && data.non_field_errors) {
          IncludeSentry(`${data.non_field_errors[0]}`);
        } else {
          if (![422, 406, 418].includes(value.status)) {
            IncludeSentry(`${value.status} http error`);
          }
        }
      }
    }

    switch (value && value.status) {
      case 400:
      case 422:
      case 406:
      case 418:
      case 404:
        return Promise.reject(error);
      case 401:
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      // Falls through to next case block
      case 403:
      case 498:
        // localStorage.clear(); // FIXME: This line was causing endless api calls once the aws request came back with a 403
        getHistory().push({
          pathname: "/login/",
          state: {
            detail:
              "You have been signed out after a period of inactivity. Please sign in again.",
          },
        });
        break;
      default:
        if (getHistory().location.pathname === "/calendar/") {
          return Promise.reject(error.response);
        }
        if (getHistory().location.pathname === "/search/") {
          return Promise.reject(error.response.request.response);
        }
        if (localStorage.getItem("token")) {
          getHistory().push("/error/");
        }
        break;
    }
  }
);

export function rGET<T = any>(url: string) {
  axios.defaults.headers.common["Accept-Language"] = "en-GB,en-US;q=0.9,en;q=0.8"; // default accept-language
  return axios.get<T>(url);
}

export function rGETwLocale(url: string, locale: string, isBlob = false) {
  axios.defaults.headers.common["Accept-Language"] = locale;
  if (isBlob) {
    return axios.get(url, { responseType: "blob" });
  }
  return axios.get(url);
}

export function rPOST(url: string, params = {}, noToken = false) {
  const no_token = /api-token-auth|password-reset|password-reset-confirm|register/.test(
    url
  );
  if (no_token) {
    delete axios.defaults.headers.common["Authorization"];
  } else {
    axios.defaults.headers.common["Authorization"] = appendToken();
  }
  if (noToken) {
    delete axios.defaults.headers.common["Authorization"];
    setTimeout(() => {
      axios.defaults.headers.common["Authorization"] = appendToken();
    }, 10); // FIXME: This is a hack for now to ensure the token is re-appended after the request
  }
  return axios.post(url, params);
}

export function rPATCH(url: string, params = {}) {
  return axios.patch(url, params);
}

export function rPUT(url: string, params = {}) {
  return axios.put(url, params);
}

export function rDELETE(url: string, params = {}) {
  return axios.delete(url, params);
}
