import React, { useContext, useEffect, useRef, useState } from "react";
import { getBeginOfPreviousMonth, getBeginOfNextMonth, getISOLocalDate } from "./dates";
import { isTouchScreen, setToolTip } from "./selector";
import { ICONS } from "../svg/icons";
import Icon from "../svg/svg_sprite";
import EditCalendar, { formNoteEvent, clearFormRadios } from "./select_dates_form";
import {
  fillCellCalendar,
  getCalendarRange,
  hideErrorMsgBox,
  translateWorms,
} from "./calendar_service";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { currentViewDays } from "./days";
import { DatePartLocalised } from "../utility/date_part_localised";
import {
  AvailabilityWindowContext,
  LocaleContext,
  PropertyIDContext,
} from "../../global_contexts";
import {
  BlockingReasonErrorContext,
  CalendarDatesContext,
  CalendarFormDataContext,
} from "./calendar_contexts";
import { Link } from "react-router-dom";
import { MonthYearDropdownULEl } from "./month_year_dropdown";
import { connect } from "react-redux";
import * as actions from "../../actions";

const M = window.M;

const Calendar = (props) => {
  const INTL = useIntl();
  const { locale } = useContext(LocaleContext);
  const { propertyID } = useContext(PropertyIDContext);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [listDays, setListDays] = useState(currentViewDays(new Date()));
  const [listMonths, setListMonths] = useState([]);
  const [readOnlyMode, setReadOnlyMode] = useState(null);
  const [style, setStyle] = useState({});
  const [deskScreenHidden, setDeskScreenHidden] = useState();
  const [activelySelecting, setActivelySelecting] = useState(false);
  const [calendarFormData, setCalendarFormData] = useState({
    type_colour: null,
    start_date_cont_disabled: false,
    end_date_cont_disabled: false,
    availability_form_disabled: false,
    save_btn_disabled: false,
    is_date_available: true,
    guest_name: null,
    worm_id: null,
    worm_type: null,
    worm_note: null,
    clean_within_two_days: false,
    clean_time: null,
  });
  const [blockingReasonError, setBlockingReasonError] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(selectedDate);
  const [calendarDates, setCalendarDates] = useState({
    start_date: null,
    end_date: null,
  });
  const { availabilityWindow } = useContext(AvailabilityWindowContext);
  const [cpErrorMessage, setCPErrorMessage] = useState("");
  const monthYearDropdownRef = useRef(null);
  const modalTSRef = useRef(null);

  useEffect(() => {
    updateCalendar();
  }, [locale, propertyID, selectedDate]);

  useEffect(() => {
    translateWorms(locale);
  }, [locale]);

  useEffect(() => {
    if (isTouchScreen) {
      M.Modal.init(modalTSRef.current, {
        dismissible: false,
        endingTop: "1%",
        onCloseStart: clearSelected,
      });
    }
    M.Dropdown.init(monthYearDropdownRef.current);
    clearSelected();
    getList();
    var elems = document.querySelectorAll(".tooltipped");
    setToolTip(elems);
    setStyle(readOnlyMode ? { display: "none" } : {});
    if (readOnlyMode) {
      setDeskScreenHidden(
        <div id="desk-screen" className="col s12 xl5">
          <div className="cal-form">
            <p>
              We are temporarily unable to update Calendar availability - please check
              back later
            </p>
          </div>
        </div>
      );
    }
  }, [propertyID]);

  useEffect(() => {
    var elems = document.querySelectorAll(".tooltipped");
    setToolTip(elems);
    addClassSelector();
  }, [selectedDate]);

  useEffect(() => {
    addClassSelector(); // immediately update the selector classes so that the following function get the correct queryselector data
    if (activelySelecting) {
      const detectedWorm = checkForWorm();
      handleFormUpdate(detectedWorm);
    }
    // Checks 10ms after calendarDates change to ensure the modal is opened when it is supposed to be
    setTimeout(() => {
      if (isTouchScreen && modalTSRef) {
        if (modalTSRef.current) {
          const modal = modalTSRef.current.M_Modal;
          if (!modal.isOpen && (calendarDates.start_date || calendarDates.end_date)) {
            modal.open();
          }
        }
      }
    }, 10);
  }, [calendarDates]);

  function updateCalendar() {
    const calendarStartDate = document
      .querySelector(".calendar_tiles_row > div:first-of-type")
      .id.slice(6);
    const calendarEndDate = document
      .querySelector(".calendar_tiles_row > div:last-of-type")
      .id.slice(6);
    checkReadOnly(calendarStartDate, calendarEndDate);
  }

  async function checkReadOnly(calendarStartDate, calendarEndDate) {
    updateReadOnly(
      await fillCellCalendar(
        propertyID,
        calendarStartDate,
        calendarEndDate,
        locale,
        props.intl,
        { setCalendarDates }
      )
    );
    translateWorms(locale);
  }

  // Check to see if we are in read only mode
  function updateReadOnly(readOnlyMode) {
    setReadOnlyMode(readOnlyMode);
  }

  async function getList() {
    let list = await getCalendarRange(propertyID);
    list = list.reverse();
    setListMonths(list);
  }

  function previousMonth(currentDate) {
    if (!document.querySelector(".left-arrow").disabled) {
      let previousMonth = getBeginOfPreviousMonth(currentDate);
      setSelectedDate(previousMonth);
      setListDays(currentViewDays(previousMonth));
      setSelectedMonth(previousMonth);
    }
  }

  function nextMonth(currentDate) {
    if (!document.querySelector(".right-arrow").disabled) {
      let nextMonth = getBeginOfNextMonth(currentDate);
      setSelectedDate(nextMonth);
      setListDays(currentViewDays(nextMonth));
      setSelectedMonth(nextMonth);
    }
  }

  function selectMonth(date) {
    setSelectedDate(date);
    setListDays(currentViewDays(date));
  }

  const addSelectingMode = () => {
    let id_sel = isTouchScreen() ? "#touch-screen" : "#desk-screen";
    document.querySelector("#clear-selected").classList.remove("hide");
    document.querySelector(`${id_sel} #availability-form`).classList.remove("hide");
    document.querySelector(`${id_sel} .bottom.row`).classList.remove("hide");
    document.querySelector(".body_days").classList.add("selecting");
    let elems = document.querySelectorAll(".calendar_tiles_row > div");
    elems.forEach((el) => el.classList.add("faded"));
  };

  const rmSelectingMode = () => {
    let id_sel = isTouchScreen() ? "#touch-screen" : "#desk-screen";
    document.querySelector("#clear-selected").classList.add("hide");
    document.querySelector(`${id_sel} #availability-form`).classList.add("hide");
    document.querySelector(`${id_sel} .bottom.row`).classList.add("hide");
    document.querySelector(".body_days").classList.remove("selecting");
    let listEls = document.querySelectorAll(".selected");
    listEls.forEach((el) => el.classList.remove("selected"));
    let listWormEls = document.querySelectorAll(".selected_worm");
    listWormEls.forEach((el) => el.classList.remove("selected_worm"));
    let listFadedEls = document.querySelectorAll(".faded");
    listFadedEls.forEach((el) => el.classList.remove("faded"));

    // reset calendar form data to default
    setCalendarFormData({
      type_colour: null,
      start_date_cont_disabled: false,
      end_date_cont_disabled: false,
      availability_form_disabled: false,
      save_btn_disabled: false,
      is_date_available: true,
      guest_name: null,
      worm_id: null,
      worm_type: null,
      worm_note: null,
      clean_within_two_days: false,
      clean_time: null,
    });
    // reset error note
    setBlockingReasonError(null);

    // reset form
    formNoteEvent();

    setToolTip();
  };

  function clearSelected() {
    clearFormRadios();
    hideErrorMsgBox();
    setCalendarDates({ start_date: null, end_date: null });
    rmSelectingMode();
  }

  function getData(start_date, end_date) {
    if ((start_date && end_date) || (!start_date && !end_date)) {
      setCalendarDates({ start_date: start_date, end_date: end_date });
    }
  }

  function processNewDateSelection(date, mouse_event) {
    let proposed_start_date = calendarDates.start_date;
    let proposed_end_date = calendarDates.end_date;

    if (mouse_event === "mousedown") {
      proposed_start_date = date;
      // since start_date_option is only triggered on mousedown we need to align the initial end date so previous data doesn't interfere with the new selection
      proposed_end_date = proposed_start_date;
    } else {
      if (date < proposed_start_date) {
        proposed_start_date = date;
      } else if (date > proposed_end_date || date < proposed_end_date) {
        proposed_end_date = date;
      }
    }

    if (proposed_start_date > proposed_end_date) {
      let temp_var = proposed_start_date; // required to not overwrite existing data
      proposed_start_date = proposed_end_date;
      proposed_end_date = temp_var;
    }

    if (proposed_start_date === null) {
      proposed_start_date = proposed_end_date;
    }
    if (proposed_end_date === null) {
      proposed_end_date = proposed_start_date;
    }

    setCalendarDates({ start_date: proposed_start_date, end_date: proposed_end_date });
  }

  const handleMousedownCalendarInteraction = (event) => {
    rmSelectingMode();
    addSelectingMode();
    setActivelySelecting(true);
    let dateToLookAt = event.currentTarget.getAttribute("date-js");
    // Check if the cell has a worm--end child and if so update the date we are selecting to align with the beginning of the worm_start
    let targetChildren = event.currentTarget.children;
    if (targetChildren.length > 0) {
      Array.from(targetChildren).forEach((child) => {
        // Only consider this when the calendarDates.end_date is set to the same as the worms end date attribute (since worms will pre-populate the calendarDates onMouseDown)
        if (
          child.classList.contains("worm--end") &&
          child.getAttribute("worm_end") === calendarDates.end_date
        ) {
          dateToLookAt = child.getAttribute("worm_start");
        }
      });
    }
    processNewDateSelection(dateToLookAt, "mousedown");
    // reset form note state
    formNoteEvent();
  };

  const handleMouseupCalendarInteraction = (event) => {
    setActivelySelecting(false);
  };

  const handleMouseenterCalendarInteraction = (event) => {
    if (activelySelecting) {
      processNewDateSelection(event.currentTarget.getAttribute("date-js"), null);
    }
  };

  function addClassSelector() {
    let elems = document.querySelectorAll(".calendar_tiles_row > div");
    elems.forEach((el) => {
      if (calendarDates.start_date <= calendarDates.end_date) {
        if (
          el.getAttribute("date-js") >= calendarDates.start_date &&
          el.getAttribute("date-js") <= calendarDates.end_date
        ) {
          el.classList.add("selected");
        } else {
          el.classList.remove("selected");
        }
      }
      if (calendarDates.start_date >= calendarDates.end_date) {
        if (
          el.getAttribute("date-js") <= calendarDates.start_date &&
          el.getAttribute("date-js") >= calendarDates.end_date
        ) {
          el.classList.add("selected");
        } else {
          el.classList.remove("selected");
        }
      }
    });
  }

  function checkForWorm() {
    let detectedWorm = null;

    // If a worm was not directly selected (eg a calendar cell)
    const selectedEls = document.querySelectorAll(".selected");
    for (let i = 0; i < selectedEls.length; i++) {
      if (selectedEls[i].hasAttribute("worm_start")) {
        const WORM_START = selectedEls[i].getAttribute("worm_start");
        const WORM_END = selectedEls[i].getAttribute("worm_end");
        // Ignore worm if it is starting on the requested end date (since the checkout half of the date is still available)
        if (
          WORM_START === calendarDates.end_date &&
          WORM_START !== calendarDates.start_date
        ) {
          continue;
        }
        calendarDates.start_date = WORM_START;
        calendarDates.end_date = WORM_END;
        addClassSelector();
        break;
      }
    }

    // clear pre-existing selected worms
    let listWormEls = document.querySelectorAll(".selected_worm");
    listWormEls.forEach((el) => el.classList.remove("selected_worm"));

    if (calendarDates.start_date && calendarDates.end_date) {
      // find selected worms
      const allWorms = document.querySelectorAll(".worm");
      if (allWorms) {
        allWorms.forEach((worm) => {
          if (worm.getAttribute("worm_start") === calendarDates.start_date) {
            worm.classList.add("selected_worm");
            detectedWorm = worm;
          }

          // check for whether a valid cleaning worm exists in the selected range
          if (worm.classList.contains("worm--clean")) {
            if (
              calendarDates.start_date !== calendarDates.end_date &&
              calendarDates.end_date === worm.getAttribute("clean_date")
            ) {
              worm.classList.add("selected_worm");
            }
          }
        });
      }
    }

    return detectedWorm;
  }

  function handleFormUpdate(detectedWorm) {
    let group_sd = detectedWorm
      ? document.querySelector(`#group_${detectedWorm.getAttribute("worm_placement")}`)
      : document.querySelector(`#group_${calendarDates.start_date}`);

    let calendar_form_data = {
      type_colour: null,
      start_date_cont_disabled: false,
      end_date_cont_disabled: false,
      availability_form_disabled: false,
      save_btn_disabled: false,
      is_date_available: true,
      guest_name: null,
      worm_id: null,
      worm_type: null,
      worm_note: null,
      clean_within_two_days: false,
      clean_time: null,
    };
    if (detectedWorm) {
      if (
        detectedWorm.classList.contains("byo_booking") ||
        detectedWorm.classList.contains("booking")
      ) {
        calendar_form_data.type_colour = "green";
        calendar_form_data.start_date_cont_disabled = true;
        calendar_form_data.end_date_cont_disabled = true;
        calendar_form_data.save_btn_disabled = true;
        calendar_form_data.is_date_available = false;
        const firstWormInChain = document.querySelector(
          `.worm[worm_start="${detectedWorm.getAttribute("worm_start")}"]`
        );
        calendar_form_data.guest_name =
          firstWormInChain.children[0].innerHTML !== ""
            ? firstWormInChain.children[0].innerHTML
            : "Guest";
        calendar_form_data.worm_type = firstWormInChain.classList.contains("booking")
          ? "booking"
          : "byo_booking";
      } else if (
        detectedWorm.classList.contains("superblock") ||
        detectedWorm.classList.contains("host_use")
      ) {
        calendar_form_data.type_colour = "blue";
        calendar_form_data.is_date_available = false;
        calendar_form_data.worm_type = detectedWorm.classList.contains("superblock")
          ? "superblock"
          : "host_use";
        calendar_form_data.worm_note = group_sd.children[0].children[1].getAttribute(
          "data-tooltip"
        );
        // check whether in past
        const today = new Date();
        if (calendarDates.start_date < today.toISOString().slice(0, 10)) {
          calendar_form_data.start_date_cont_disabled = true;
          calendar_form_data.availability_form_disabled = true;
        }
      }
      calendar_form_data.worm_id = detectedWorm.getAttribute("worm_id");

      // check for cleaning worm
      let endGroupWorms = document.querySelectorAll(
        `#group_${detectedWorm.getAttribute("worm_end")} div.worm`
      );
      let clean_worm = null;
      endGroupWorms.forEach((w) => {
        if (detectedWorm && w.classList.contains("worm--clean")) {
          clean_worm = w;
        }
      });
      if (clean_worm !== null) {
        const diffTime = Math.abs(
          new Date(detectedWorm.getAttribute("worm_end")) - new Date()
        );
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays <= 2) {
          calendar_form_data.start_date_cont_disabled = true;
          calendar_form_data.end_date_cont_disabled = true;
          calendar_form_data.availability_form_disabled = false;
          calendar_form_data.save_btn_disabled = true;
          calendar_form_data.clean_within_two_days = true;
        }
        // Add cleaning time to calendar form data for non-desktop form modal usuage
        calendar_form_data.clean_time = clean_worm.getAttribute("cleaning_time");
      }
    } else {
      if (group_sd && group_sd.children[0].classList.contains("unavailable")) {
        calendar_form_data.is_date_available = false;
      }
    }

    setCalendarFormData(calendar_form_data);
  }

  return (
    <div className="row" onMouseUp={handleMouseupCalendarInteraction}>
      <div className="col s12">
        <h4>
          <FormattedMessage id="calendar.title" defaultMessage="Calendar" />
        </h4>
      </div>
      <div className="col s12">
        <p className="spacer hide-on-large">
          <FormattedMessage
            id="calendar.description_mobile"
            defaultMessage="Your calendar is set to be {availability_window}. You can update your availability on this page by clicking on the calendar."
            values={{ availability_window: availabilityWindow.toLowerCase() }}
          />
        </p>
        <p className="spacer hide-on-tablet">
          <FormattedMessage
            id="calendar.description_desktop"
            defaultMessage="Your calendar is set to be {availability_window}. You can update your
          availability on this page either by clicking on the calendar or by selecting
          a date on the right."
            values={{ availability_window: availabilityWindow.toLowerCase() }}
          />
        </p>
      </div>
      <MonthYearDropdownULEl
        id="dropdownMonths"
        classes="dropdown-content"
        listMonths={listMonths}
        updateOnClick={selectMonth}
      />
      {/* ***** */}
      <div id="cal-container" className="col s12 xl7">
        <div className="calendar">
          <div className="calendar_header">
            <button
              id="month_year_dropdown"
              ref={monthYearDropdownRef}
              className="dropdown-trigger"
              data-target="dropdownMonths">
              <DatePartLocalised
                date={selectedDate}
                options={{ month: "long", year: "numeric" }}
              />
            </button>
            <button id="clear-selected" className="hide" onClick={clearSelected}>
              <FormattedMessage
                id="calendar.button.clear_selection"
                defaultMessage="Clear Selection"
              />
            </button>
            <div className="arr-contain">
              <button
                className="left-arrow"
                value={selectedDate}
                onClick={() => previousMonth(selectedDate)}>
                <Icon
                  icon={ICONS.LEFT_ARROW.d}
                  width={21}
                  height={21}
                  viewBox="0 0 24 24"
                />
              </button>
              <button
                className="right-arrow"
                value={selectedDate}
                onClick={() => nextMonth(selectedDate)}>
                <Icon
                  icon={ICONS.RIGHT_ARROW.d}
                  width={21}
                  height={21}
                  viewBox="0 0 24 24"
                />
              </button>
            </div>
          </div>
          <div className="calendar_tiles">
            <ul className="header_days">
              <li>
                {/* Mon */}
                <DatePartLocalised date={"2000-01-03"} options={{ weekday: "short" }} />
              </li>
              <li>
                {/* Tue */}
                <DatePartLocalised date={"2000-01-04"} options={{ weekday: "short" }} />
              </li>
              <li>
                {/* Wed */}
                <DatePartLocalised date={"2000-01-05"} options={{ weekday: "short" }} />
              </li>
              <li>
                {/* Thu */}
                <DatePartLocalised date={"2000-01-06"} options={{ weekday: "short" }} />
              </li>
              <li>
                {/* Fri */}
                <DatePartLocalised date={"2000-01-07"} options={{ weekday: "short" }} />
              </li>
              <li>
                {/* Sat */}
                <DatePartLocalised date={"2000-01-08"} options={{ weekday: "short" }} />
              </li>
              <li>
                {/* Sun */}
                <DatePartLocalised date={"2000-01-09"} options={{ weekday: "short" }} />
              </li>
            </ul>
            <ul className="body_days">
              <div id="spinner" className="loading" />
              <div id="past_screen" className="past_screen" />
              <div className="calendar_tiles_row">
                {listDays.map((d, i) => (
                  <div
                    data-target="modalTS"
                    className="modal-trigger"
                    id={`group_${getISOLocalDate(d.date)}`}
                    key={`group_${getISOLocalDate(d.date)}`}
                    date-js={getISOLocalDate(d.date)}
                    onMouseDown={handleMousedownCalendarInteraction}
                    onMouseUp={handleMouseupCalendarInteraction}
                    onMouseEnter={handleMouseenterCalendarInteraction}>
                    <li
                      id={`cell_${getISOLocalDate(d.date)}`}
                      key={d.date}
                      value={d.date}
                      weekday={d.week_day}>
                      <span
                        className={
                          d.extra_day ? "calendar_number light_grey" : "calendar_number"
                        }>
                        {d.day}
                      </span>
                      <small
                        className="note tooltipped hide"
                        data-position="top"
                        data-tooltip="">
                        <Icon
                          icon={ICONS.INFO_EMPTY.d}
                          width={14}
                          height={14}
                          viewBox="0 0 24 24"
                          color="#f5a423"
                        />
                      </small>
                    </li>
                  </div>
                ))}
              </div>
            </ul>
          </div>
          <div className="key-container mt-25">
            <div>
              <div className="available-box">
                <span>
                  <FormattedMessage
                    id="dashboard.calendar.available"
                    defaultMessage="Available"
                  />
                </span>
              </div>
            </div>
            <div>
              <div className="blocked-box">
                <span>
                  <FormattedMessage
                    id="dashboard.calendar.blocked"
                    defaultMessage="Blocked"
                  />
                </span>
              </div>
            </div>
            <div className="cleaning-box--first_row">
              <Icon
                icon={ICONS.CLEANING.d}
                width={20}
                height={20}
                viewBox="0 0 40 40"
              />
              <div className="cleaning-box">
                <span>
                  <FormattedMessage
                    id="calendar.key.cleaning"
                    defaultMessage="Cleaning"
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="key-container mt-25">
            <div
              data-js="cleaning_icon_to_be_used_in_cleaning_worms"
              className="cleaning-box--second_row">
              <Icon
                icon={ICONS.CLEANING.d}
                width={20}
                height={20}
                viewBox="0 0 40 40"
              />
              <div className="cleaning-box">
                <span>
                  <FormattedMessage
                    id="calendar.key.cleaning"
                    defaultMessage="Cleaning"
                  />
                </span>
              </div>
            </div>
            <div>
              <div className="host_use-box">
                <span>
                  <FormattedMessage
                    id="calendar.key.host_use"
                    defaultMessage="Your use"
                  />
                </span>
              </div>
            </div>
            <div>
              <div className="booked-box">
                <span>
                  <FormattedMessage id="calendar.booking" defaultMessage="Booking" />
                </span>
              </div>
            </div>
            <div>
              <div className="byo_booked-box">
                <abbr
                  title={INTL.formatMessage({
                    id: "calendar.abbr_byo_text",
                    defaultMessage: "Bring Your Own",
                  })}>
                  {INTL.formatMessage({
                    id: "calendar.abbr_byo",
                    defaultMessage: "BYO",
                  })}
                </abbr>
                <p>
                  <FormattedMessage id="calendar.booking" defaultMessage="Booking" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CalendarFormDataContext.Provider
        value={{ calendarFormData, setCalendarFormData }}>
        <BlockingReasonErrorContext.Provider
          value={{ blockingReasonError, setBlockingReasonError }}>
          <CalendarDatesContext.Provider value={{ calendarDates, setCalendarDates }}>
            <div id="touch-screen" className="col s12 xl5" style={style}>
              {/* Modal */}
              <div id="modalTS" className="modal" ref={modalTSRef}>
                <span className="close modal-close">
                  <Icon
                    icon={ICONS.DELETE.d}
                    width={15}
                    height={15}
                    viewBox="0 0 24 24"
                    color="#ffffff"
                  />
                </span>
                <div className="cal-form">
                  <EditCalendar
                    sendData={getData}
                    addSelectingMode={addSelectingMode}
                    removeSelectingMode={rmSelectingMode}
                    setCPErrorMessage={setCPErrorMessage}
                  />
                </div>
                {cpErrorMessage.length > 3 ? (
                  <div
                    id="CPCalendarErrorMsg"
                    className="cal-form cal-form--error hide"
                    style={style}>
                    <p>{cpErrorMessage}</p>
                  </div>
                ) : (
                  <div
                    id="CPCalendarErrorMsg"
                    className="cal-form cal-form--error hide"
                    style={style}>
                    <p>
                      <FormattedMessage
                        id="unexpected_error_part_one"
                        defaultMessage="An unexpected error has occurred with status code"
                      />{" "}
                      <b>{cpErrorMessage}</b>,{" "}
                      <FormattedMessage
                        id="unexpected_error_part_two"
                        defaultMessage="if this continues to occur, please"
                      />{" "}
                      <Link className="booking_text_box--link" to="/contact-us/">
                        <FormattedMessage
                          id="calendar.form.contact"
                          defaultMessage="contact"
                        />
                      </Link>{" "}
                      <FormattedMessage
                        id="unexpected_error_part_three"
                        defaultMessage="your account manager and send them a screenshot alongwith a description of what you were attempting."
                      />
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div id="desk-screen" className="col s12 xl5" style={style}>
              <div className="cal-form">
                <EditCalendar
                  sendData={getData}
                  addSelectingMode={addSelectingMode}
                  removeSelectingMode={rmSelectingMode}
                  setCPErrorMessage={setCPErrorMessage}
                />
              </div>
              {cpErrorMessage.length > 3 ? (
                <div
                  id="CPCalendarErrorMsg"
                  className="cal-form cal-form--error hide"
                  style={style}>
                  <p>{cpErrorMessage}</p>
                </div>
              ) : (
                <div
                  id="CPCalendarErrorMsg"
                  className="cal-form cal-form--error hide"
                  style={style}>
                  <p>
                    <FormattedMessage
                      id="unexpected_error_part_one"
                      defaultMessage="An unexpected error has occurred with status code"
                    />{" "}
                    <b>{cpErrorMessage}</b>,{" "}
                    <FormattedMessage
                      id="unexpected_error_part_two"
                      defaultMessage="if this continues to occur, please"
                    />{" "}
                    <Link className="booking_text_box--link" to="/contact-us/">
                      <FormattedMessage
                        id="calendar.form.contact"
                        defaultMessage="contact"
                      />
                    </Link>{" "}
                    <FormattedMessage
                      id="unexpected_error_part_three"
                      defaultMessage="your account manager and send them a screenshot alongwith a description of what you were attempting."
                    />
                  </p>
                </div>
              )}
            </div>
          </CalendarDatesContext.Provider>
        </BlockingReasonErrorContext.Provider>
      </CalendarFormDataContext.Provider>

      {deskScreenHidden}
    </div>
  );
};

export default connect(null, actions)(injectIntl(Calendar));
