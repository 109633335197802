import React, { useContext, useEffect, useRef, useState } from "react";
import { MATERIALIZE_REF } from "../../contexts";
import { MultiSelectField } from "../../schema";

export const InputMultiSelectComponent = (props: {
  onValueChange: any;
  field: MultiSelectField;
}) => {
  const [value, setValue] = useState<any>([]);
  const multiSelectRef = useRef<any>(null);
  const MATERIALIZE = useContext(MATERIALIZE_REF);

  useEffect(() => {
    if (props.field.value) {
      // convert to array of strings for local usuage
      const stringParsedArr = props.field.value.map((option: any) =>
        option?.toString()
      );
      setValue(stringParsedArr);
    }

    // Initialize Materialize select
    if (multiSelectRef.current) {
      // slight delay on materialize initialization to ensure above value correctly shows when initialized
      setTimeout(() => {
        MATERIALIZE.FormSelect.init(multiSelectRef.current, { classes: "filled-in" });

        // Add filled-in class to inputs inside of multi-select screen so that the checkbox's within appear the same as elsewhere
        const nestedInputs = multiSelectRef.current.parentElement.querySelectorAll(
          '[type="checkbox"]'
        );
        nestedInputs.forEach((input: Element) => input.classList.add("filled-in"));
      }, 100);
    }

    // Cleanup function
    return () => {
      // Destroy Materialize select when the component unmounts
      if (multiSelectRef.current) {
        const instance = MATERIALIZE.FormSelect.getInstance(multiSelectRef.current);
        if (instance) {
          instance.destroy();
        }
      }
    };
  }, []);

  useEffect(() => {
    // Update Materialize select when the field changes (e.g. when the locale is changed)
    MATERIALIZE.FormSelect.init(multiSelectRef.current, { classes: "filled-in" });
  }, [props.field]);

  return (
    <select
      ref={multiSelectRef}
      id={props.field.key}
      required={props.field.required}
      value={value}
      onChange={(e) => {
        if (multiSelectRef.current) {
          // Retreive the selected values via the materialize reference
          const selectedOptionsArr = Array.from(multiSelectRef.current.options)
            .filter((option: any) => option.selected)
            .map((option: any) => option.value);

          setValue(selectedOptionsArr);

          // convert to array of integers for PATCH
          const intParsedArr = selectedOptionsArr.map((option: any) =>
            parseInt(option)
          );
          props.onValueChange(e.currentTarget, intParsedArr);
        }
      }}
      multiple>
      {props.field.choices.map((choice, index) => (
        <option key={`${props.field.key}_${index}`} value={choice.value}>
          {choice.label}
        </option>
      ))}
    </select>
  );
};
